import React, { useRef, useState } from 'react';

import { Skeleton } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import logo from 'src/assets/images/logo/full-logo-plaine.svg';
import Lock from 'src/assets/images/menu-icons/lock-dropdown-icon.svg';
import LogOut from 'src/assets/images/menu-icons/logout-dropdown-icon.svg';
import User from 'src/assets/images/menu-icons/user-dropdown-icon.svg';
import AvatarPlaceholder from 'src/assets/images/stub-for-avatar.svg';
import Button from 'src/components/common/button/CustomButton';
import Icon, { IconType, SvgGradient } from 'src/components/common/Icon';
import ModalWindow from 'src/components/common/modals/modal-window/ModalWindow';
import ChangePasswordModal from 'src/components/customer/profile/components/ChangePassword/ChangePasswordModal';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { UserLogOut } from 'src/services/auth';
import { useAppSelector } from 'src/store/store';

import styles from './Header.module.css';

const Header = () => {
  const navigate = useNavigate();
  const [displayConfirm, setDisplayConfirm] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const { currentUser } = useAppSelector((state) => state.currentUser);
  const ref = useRef();

  useOnClickOutside({ ref, callback: () => setMenuOpened(false) });

  const handleApproveLogout = () => {
    UserLogOut();
  };

  const handleLogout = () => {
    setDisplayConfirm(true);
    setMenuOpened(false);
  };

  const handleRedirectToProfile = () => {
    navigate('/profile');
    setMenuOpened(false);
  };

  const handleInvite = () => {
    navigate('/invite-user');
  };

  const handleChangePassword = () => {
    setChangePasswordModal(true);
    setMenuOpened(false);
  };

  return (
    <>
      <nav className={styles.navbar}>
        <Link to="/">
          <img src={logo} alt="Scorebird_logo" className={styles.logo} />
        </Link>
        <div className={styles.actions}>
          <Button className={styles.invite} onClick={handleInvite}>Invite User</Button>
          <Button className={styles.orderDevice} onClick={() => navigate('/device-order', { state: { mainPage: true } })} version="primary">
            <span>Order Device</span>
          </Button>
          <Button className={styles.tutorial} onClick={() => window.open('https://www.youtube.com/@scorebird2247', '_blank')} version="primary">
            <Icon type={IconType.Youtube} fill={SvgGradient.GradientDeepBlue} />
            <span>Tutorials</span>
          </Button>
          <span className={styles.userName}>
            {currentUser?.first_name && currentUser?.last_name ? `${currentUser?.first_name} ${currentUser?.last_name}` : <Skeleton width={200} height={40} /> }
          </span>
          <div ref={ref} className={styles.menuWrapper}>
            <Button onClick={() => setMenuOpened(!menuOpened)} version="icon">
              <img
                src={AvatarPlaceholder}
                alt="avatar"
              />
            </Button>
            {menuOpened && (
              <div className={styles.menu}>
                <Button version="link" onClick={handleRedirectToProfile}><img src={User} alt="account" /> Profile</Button>
                <Button version="link" onClick={handleChangePassword}><img src={Lock} alt="lock" />Change Password</Button>
                <Button version="link" onClick={handleLogout}><img src={LogOut} alt="logout" />Sign Out</Button>
              </div>
            )}
          </div>
        </div>
      </nav>
      {displayConfirm
        && (
        <ModalWindow isOpen={displayConfirm} className={styles.modal}>
          <h4>Are you sure you want to sign out?</h4>
          <div className={styles.confirmationModalButtons}>
            <Button version="primary" onClick={() => setDisplayConfirm(false)}>Cancel</Button>
            <Button version="secondary" onClick={handleApproveLogout}>Sign Out</Button>
          </div>
        </ModalWindow>
        )}

      {changePasswordModal && (
      <ChangePasswordModal user={currentUser} onClose={() => setChangePasswordModal(false)} isOpen={changePasswordModal} />
      )}
    </>
  );
};

export default Header;
