/* eslint-disable */
import axiosInstance from 'axios';
import { Service } from 'axios-middleware';

import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import { getIdToken } from 'src/util/getIdToken';
import { UserLogOut } from './auth';

// -----------------------------------------------------------------------------

// Create instance and default config options
const createAxios = () => {
  return axiosInstance.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const axios = createAxios();
axios.interceptors.request.use(async (config) => {
  await getIdToken().then((idToken) => {
    if (idToken !== null) {
      config.headers.Authorization = `Bearer ${idToken}`;
    }
  });

  return config;
});

const apiUrl = `/${process.env.REACT_APP_OLDAPI_VERSION}/`;
const apiUrlv4 = `/${process.env.REACT_APP_NEWAPI_VERSION}/`;
const service = new Service(axios);
const { CancelToken } = axiosInstance;
let ServiceInstance = null;
let cancel = () => {
  console.warn('CancelCall is empty');
};

service.register({
  onRequest(config) {
    if (
      config.url.match(`${apiUrl}auth/`)
      // || config.url.match(`${apiUrl}authNew/`)
      // || config.url.match(`${apiUrlv4}auth/`)
      || config.url.match(`${apiUrl}sport`)
      || config.url.match(`${apiUrl}deviceOrders`)
      || config.url.match(`${apiUrl}facilityType`)
      || config.url.match(`${apiUrl}scoreboard`)
      || config.url.match(`${apiUrl}school`)
      || config.url.match(`${apiUrlv4}school`)
      || config.url.match(`${apiUrl}schedule`)
      || config.url.match(`${apiUrlv4}schedule`)
      || config.url.match(`${apiUrlv4}game`)
      || config.url.match(`${apiUrl}game`)
      || config.url.match(`${apiUrl}team`)
      || config.url.match(`${apiUrl}device`)
      || config.url.match(`${apiUrl}shadow`)
      || config.url.match(`${apiUrl}team_grades`)
      || config.url.match(`${apiUrl}facilityType`)
      || config.url.match(`${apiUrl}facility`)
      || config.url.match(`${apiUrl}flaggedGame`)
      || config.url.match(`${apiUrl}manuallyFedScores`)
      || config.url.match(`${apiUrl}user`)
      || config.url.match(`${apiUrl}notifications`)
      || config.url.match(`${apiUrlv4}team`)
      || (config.url.match(`${apiUrl}user`) && !config.url.match(`${apiUrl}userRole`))
      || config.url.match(`${apiUrl}pitch_appearance`)
      || config.url.match(`${apiUrlv4}associations`)
    ) {
      // add token check only after auth
      // if (config.headers.Authorization) {
      //   const decoded = decode(config.headers.Authorization);
      //   // If the token is expired, the user will be logged out
      //   if (decoded.exp < Date.now() / 1000) {
      //     snackbarService.error('Your session has expired. Please sign in again.');
      //     UserLogOut();
      //   }
      // }
    }
    return config;
  },
  onSync(promise) {
    return promise;
  },
  onResponse(response) {
    return response;
  },

  onResponseError(error) {
    const data = JSON.parse(error?.response?.data);
    if ((error?.response.status === 403) && (data.message === 'Authentication required')) {
      UserLogOut();
    }
    throw error;
  },
});

class ScorebirdService {
  hostname = window && window.location && window.location.hostname;

  groupsPromise = null;

  devicesPromise = null;

  teamsPromise = null;

  gamesPromise = {};

  schoolsPromise = null;

  schoolsByStatePromise = {};

  // schoolsAndTeamsByStatePromise = {}
  allFacilitiesPromise = null;

  devicesData;

  groupsData;

  teamsData;

  gamesData = {};

  devicesById = {};

  groupsById = {};

  teamsById = {};

  gamesById = {};

  gamesActiveStartDate = '';

  gamesActiveEndDate = '';

  userDataByEmailPromise = {};

  constructor() {
    // this.axiosCancelSource = axios.CancelToken.source()
    if (!ServiceInstance) {
      ServiceInstance = this;
    }
    return ServiceInstance;
  }

  cancelCall() {
    cancel();
  }

  getSchoolsByuser(params) {
    return axios
      .post(`${apiUrl}school/getSchoolsByuser/`, params, {
        cancelToken: new CancelToken((c) => {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error("Can't get schools by user. Please, try later.");
          throw res.data.message;
        }
        return res.data;
      })
      .catch((err) => {
        if (axiosInstance.isCancel(err)) {
          return { cancel: true };
        }
        console.error('Error in get schools by user:', err);
        snackbarService.error("Can't get schools by user. Please, try later.");
        throw { success: false, message: err };
      });
  }

  getFacilitiesPaginated(params) {
    return axios
      .post(`${apiUrl}facility/getAll/`, params, {
        cancelToken: new CancelToken((c) => {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error("Can't get all paginated facilities. Please, try later.");
          throw res.data.message;
        }
        return res.data;
      })
      .catch((err) => {
        if (axiosInstance.isCancel(err)) {
          return { cancel: true };
        }
        console.error('Error in get all paginated facilities:', err);
        snackbarService.error("Can't get all paginated facilities. Please, try later.");
        throw { success: false, message: err };
      });
  }

  getFacilities() {
    if (!this.allFacilitiesPromise) {
      this.allFacilitiesPromise = axios
        .get(`${apiUrl}facility/allfacilities/`)
        .then((res) => {
          if (!res.data.success) {
            snackbarService.error("Can't get facilities. Please, try later.");
            throw res.data.message;
          }
          return res.data;
        })
        .catch((error) => {
          console.error('Error in get facilities:', error);
          snackbarService.error("Can't get facilities. Please, try later.");
          throw error;
        });
    }
    return this.allFacilitiesPromise;
  }

  getSchedulesById(params) {
    return axios
      .post(`${apiUrl}schedule/getSchedules/`, params)
      .then((res) => {
        if (res.status !== 200 && res.status !== 204 && res.status !== 304) {
          snackbarService.error("Can't get schedules. Please, try later.");
          console.error('Error in get schedules by id:', res.message);
          throw res.message;
        }
        return res.data;
      })
      .catch((error) => {
        console.error('Error in get schedules by id:', error);
        snackbarService.error("Can't get schedules. Please, try later.");
        throw error;
      });
  }

  getFacilityBySchoolId(params) {
    return axios
      .post(`${apiUrl}school/facility/`, params)
      .then((res) => {
        if (res.status !== 200 && res.status !== 204 && res.status !== 304) {
          snackbarService.error("Can't get facilities. Please, try later.");
          throw res.data.message;
        }
        return res.data.data;
      })
      .catch((error) => {
        console.error('Error in get facilities by school id:', error);
        snackbarService.error("Can't get facilities. Please, try later.");
        throw error;
      });
  }

  getSchoolData(schoolId) {
    return axios
      .get(`${apiUrl}school/${schoolId}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.error('Error in get school data by id:', error);
        if (error.response.status !== 422) {
          snackbarService.error("Can't get school details. Please, try later.");
        }
        throw error;
      });
  }

  getUserDataByEmail(email, isRefetch) {
    if (!this.userDataByEmailPromise[email] || isRefetch) {
      this.userDataByEmailPromise[email] = axios
        .get(`${apiUrlv4}user/data/${email}`)
        .then((res) => {
          if (!res.data.success) {
            snackbarService.error("Can't get user details. Please, try later.");
            throw res.data.message;
          }
          return res.data;
        })
        .catch((error) => {
          console.error('Error in get user data by email:', error);
          snackbarService.error("Can't get user details. Please, try later.");
          throw error;
        });
    }
    return this.userDataByEmailPromise[email];
  }

  getUser(userId) {
    return axios
      .get(`${apiUrl}user/getUserData/${userId}`)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error("Can't get user details. Please, try later.");
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        if (error.response.status !== 422) {
          snackbarService.error('Something went wrong. Please, try later.');
        }
        console.error('Error in get user by id:', error);
        throw error;
      });
  }

  saveSchoolAddress(params) {
    return axios
      .put(`${apiUrl}school/${params[0]._id}`, params[0])
      .then((res) => {
        this.schoolsPromise = null;
        if (!res.data.success) {
          snackbarService.error("Can't save school changes. Please, try later.");
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        console.error('Error in saving school details:', error);
        snackbarService.error("Can't save school changes. Please, try later.");
        throw { success: false, err: error };
      });
  }

  adduserFromUserpageNew(params) {
    return axios
      .post(`${apiUrl}user/adduserNew`, params)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.error('Error in add new user:', error);
        snackbarService.error("Can't create new user. Please, try later.");
        throw error;
      });
  }

  getTeamData(teamId) {
    return axios
      .get(`${apiUrl}team/detail/${teamId}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.error('Error in get team data by id:', error);
        if (error.response.status !== 422) {
          snackbarService.error("Can't get team details. Please, try later.");
        }
        throw error;
      });
  }

  addTeam(params) {
    return axios
      .post(`${apiUrl}team`, params)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error("Can't save changes. Please, try later.");
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        snackbarService.error("Can't save changes. Please, try later.");
        console.error('Error when creating new team:', error);
        throw error;
      });
  }

  getUsersBySchoolId(schoolId) {
    return axios
      .get(`${apiUrl}user/schoolUsers/${schoolId}`)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error('Something went wrong. Please, try later.');
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error get users by school id:', error);
        throw error;
      });
  }

  signInNew(params) {
    return axios
      .post(`${apiUrl}authNew/signInNew`, params)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error('Error with sign in:', error);
        throw error;
      });
  }

  // This method is copy of updateScheduleGame updated for Games feature needs,
  // is simple to "updateScheduleGame" with some improvements
  // Old request not deleted because it used in another pages
  updateGame(game) {
    return axios
      .put(`${apiUrlv4}schedule/${game._id}`, game)
      .then((res) => {
      const {
        _id,
        name,
        mapping,
        team_away_school,
        team_home_school,
        facility_mongo_id,
        schedule_id,
        last_update_ts
      } = res.data.updatedSchedule;
        const game = {
          _id,
          name,
          mapping,
          team_away_school,
          team_home_school,
          facility_mongo_id,
          schedule_id,
          last_update_ts,
        };
        return { data: game, success: res.success };
      })
      .catch((error) => {
        console.error('Error when updating game:', error);
        throw error;
      });
  }

  getDevicesBySchool(schoolId) {
    return axios
      .get(`${apiUrl}device/getDeviceBySchool/${schoolId}`)
      .then((res) => {
        if (res.status !== 200 && res.status !== 204 && res.status !== 304) {
          console.error('Error when try to get devices by school:', res.message);
          snackbarService.error('Something went wrong. Please, try later.');
          throw res;
        }
        return res;
      })
      .catch((error) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get devices by school:', error);
        throw error;
      });
  }

  getAvailableDevices() {
    return axios
      .get(`${apiUrl}device/getAvailableDevices/`)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error('Something went wrong. Please, try later.');
          throw res.data.message;
        }
        return res.data.data;
      })
      .catch((error) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get available devices:', error);
        throw error;
      });
  }

  getDeviceDetails(deviceId) {
    return axios
      .get(`${apiUrl}device/detail/${deviceId}`)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error('Something went wrong. Please, try later.');
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        if (error.response.status !== 422) {
          snackbarService.error(`Device ${deviceId} is not responding. Please, try again later.`);
        }
        console.error('Error when try to get device details:', error);
        throw error;
      });
  }

  assignTeamsToFacility(params) {
    const { facilityId } = params;
    return axios
      .put(`${apiUrl}facility/assignTeams/${facilityId}`, params)
      .then((res) => {
        if (res.status !== 200 && res.status !== 204 && res.status !== 304) {
          console.error('Error when try to assign teams to the facility:', res.message);
          snackbarService.error('Something went wrong. Please, try later.');
          throw res;
        }
        return res.data;
      })
      .catch((err) => {
        console.error('Error when try to assign teams to the facility:', err);
        throw err;
      });
  }

  getGameDetails(scheduleId) {
    const isDev = window.location.hash === '#/calendar-dev';
    const apiFolder = isDev ? 'getGameDetailDev' : 'getGameDetail';
    return axios
      .get(`${apiUrl}game/${apiFolder}/${scheduleId}`)
      .then((res) => {
        if (res.status !== 200 && res.status !== 204 && res.status !== 304) {
          console.error('Error when try to get game details:', res.message);
          snackbarService.error('Something went wrong. Please, try later.');
          throw res;
        }
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get game details:', err);
        throw err;
      });
  }

  changeUserPasswordByAdmin(payload) {
    return axios
      .put(`${apiUrl}user/changeUserPasswordByAdmin`, payload)
      .then((res) => {
        // didn't add a res.success check because there is processing in the component
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to change password:', err);
        throw err;
      });
  }

  gameFinalize(schedule_id) {
    return axios
      .get(`${apiUrl}game/gameFinalize/${schedule_id}/true`)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error when try to finalize game:', res.message);
          throw res;
        }
        return res.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to finalize game:', err);
        throw err;
      });
  }

  saveScores(params) {
    return axios
      .post(`${apiUrl}game/saveScores`, params)
      .then((res) => {
        // didn't add res.success checking as not sure about response model
        return res;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to save scores:', err);
        throw err;
      });
  }

  schoolQuickUpdate(params, schoolId) {
    return axios
      .put(`${apiUrl}school/schoolQuickUpdate/${schoolId}`, params)
      .then((res) => {
        // didn't add res.success checking as not sure about response model
        return res;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to school quick update:', err);
        throw err;
      });
  }

  getFlaggedGames(user_id) {
    return axios
      .get(`${apiUrl}flaggedGame/${user_id}`)
      .then((resolve) => {
        return resolve.data;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to get flagged games:', err);
        return err;
      });
  }

  setGameFlagged(payload) {
    return axios
      .post(`${apiUrl}flaggedGame`, payload)
      .then((response) => {
        if (!response.data.success) {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error when try to flag game:', response.data.message);
          throw response.data.message;
        }
        return response;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to flag game:', err);
        return err;
      });
  }

  deleteGameFlagged(payload) {
    return axios
      .delete(`${apiUrl}flaggedGame`, { data: payload })
      .then((response) => {
        if (!response.data.success) {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error when try to delete game from flagged list:', response.data.message);
          throw response.data.message;
        }
        return response;
      })
      .catch((err) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to delete game from flagged list:', err);
        return err;
      });
  }

  getFacilityById(facility_id) {
    return axios
      .get(`${apiUrl}facility/${facility_id}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error('Error when try to get facility by id:', err);
        throw err;
      });
  }

  getTeamsByIds(teamsArray) {
    return axios
      .post(`${apiUrlv4}team/getTeamsByIds`, teamsArray)
      .then((res) => {
        if (res.status !== 200 && res.status !== 204 && res.status !== 304) {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error when try to get teams by ids:', res.data.message);
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.log('Error when try to get teams by ids: ', error);
        return error;
      });
  }

  getAssociationsList() {
    return axios.get(`${apiUrlv4}associations`).then((res)=>{
      if (res.status === 200) {
        if (!res.data || !res.data.success) {
          snackbarService.error('Error on getting associations list. Please try again later...');
          return;
        }
        return res.data.associations
      }
    }).catch(e => {
      snackbarService.error(e.message)
    })
  }

  removeUser(payload){
    return axios.delete(`${apiUrlv4}user/remove`, {data: {
        user_id: payload.user_id,
        user_id_to_remove: payload.user_id_to_remove,
        schools_to_remove: payload.schools_to_remove
      }}).then((res)=>{
      if (res.status === 200) {
        if (!res.data || !res.data.success) {
          snackbarService.error('Error on removing user. Please try again later...');
          return;
        }
        return res.data.success
      }
      return res
    }).catch(e => {
      snackbarService.error(e.message)
    })
  }
}

export default ScorebirdService;
