import { AxiosInstance } from 'axios';

import { ApiResponse } from './types';

interface State {
  code: string;
  name: string;
  time_zones: string[];
}

export interface Country {
  code: string;
  states: State[];
}

export const CountriesService = (axios: AxiosInstance) => {
  const getCountries = async () => {
    const res = await axios.get<ApiResponse<Country[]>>('/v4/countries');
    return res.data.data;
  };

  return {
    getCountries,
  };
};
