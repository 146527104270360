import { AxiosError, AxiosInstance } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import {
  GetTeamSchedulesPayload,
  GetTeamSchedulesRes,
  IInactivateSchedule,
  IMergeSchedules,
  ISeason,
  SchedulePayload,
  ScheduleRes,
  GetSchedulesByDevicePayload,
  GetSchedulesByDeviceRes,
  TournamentsRes,
  GameDaySchedule,
} from 'src/services/interfaces/Schedules.interface';
import dateFormat from 'src/util/dateFormat/dateFormat';

import { apiUrlV3, apiUrlV4 } from '../constants/api-versions';

export const SchedulesService = (axios:AxiosInstance) => {
  const InactivateSchedule = ({ scheduleId }: IInactivateSchedule) => {
    return axios.put(
      `${apiUrlV3}schedule/inactivateSchedule/${scheduleId}`,
      { schedule_id: scheduleId },
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  const useInactivateSchedule = () => {
    const inactivateSchedule = (scheduleId: string) => axios.put(
      `${apiUrlV3}schedule/inactivateSchedule/${scheduleId}`,
      { schedule_id: scheduleId },
    ).then((res) => {
      return res.data;
    });

    return useMutation(
      {
        mutationKey: ['mutation-schedule-inactivateSchedule'],
        mutationFn: inactivateSchedule,
        onSuccess: () => {
          snackbarService.success('Successfully deleted!');
        },
        onError: (error) => {
          snackbarService.error("Can't delete schedule. Please, try later.");
          console.error("Can't delete schedule", error);
        },
      },
    );
  };

  const useAddSchedule = () => {
    const addSchedule = (payload: SchedulePayload) => axios
      .post(`${apiUrlV4}schedule/addSchedule`, payload)
      .then((res) => {
        const resData: ScheduleRes = res.data;
        return resData;
      });

    return useMutation(
      {
        mutationKey: ['mutation-schedule-add'],
        mutationFn: addSchedule,
        onError: (error) => {
          snackbarService.error("Can't add schedule. Please, try later.");
          console.error("Can't add schedule:", error);
        },
      },
    );
  };

  const useUpdateSchedule = () => {
    const updateSchedule = (payload: SchedulePayload) => axios
      .put(`${apiUrlV4}schedule/${payload._id}`, payload)
      .then((res) => {
        const resData: ScheduleRes = res.data;
        return resData;
      });

    return useMutation(
      {
        mutationKey: ['mutation-schedule-update'],
        mutationFn: updateSchedule,
        onError: (error) => {
          snackbarService.error("Can't update schedule. Please, try later.");
          console.error("Can't update schedule:", error);
        },
      },
    );
  };

  const usePartialUpdateSchedule = () => {
    const partialUpdateSchedule = (
      payload: Pick<SchedulePayload, '_id'> & Partial<SchedulePayload>
    ) =>
      axios.patch(`${apiUrlV4}schedule/${payload._id}`, payload).then((res) => {
        const resData: ScheduleRes = res.data;
        return resData;
      });

    return useMutation(
      {
        mutationKey: ['mutation-schedule-partial-update'],
        mutationFn: partialUpdateSchedule,
        onError: (error) => {
          snackbarService.error("Can't update schedule. Please, try later.");
          console.error("Can't update schedule:", error);
        },
      },
    );
  };

  const useMakeLive = () => {
    const makeLive = (scheduleId: string) => axios
      .put(`${apiUrlV4}schedule/${scheduleId}/make-live`);

    return useMutation(
      {
        mutationKey: ['mutation-schedule-make-live'],
        mutationFn: makeLive,
        onError: (error) => {
          snackbarService.error("Can't make schedule live. Please, try later.");
          console.error("Can't make schedule live:", error);
        },
      },
    );
  };

  const useUnlatchSchedule = () => {
    const unlatchSchedule = ({
      schedule,
      email,
    }: {
      schedule: GameDaySchedule;
      email: string;
    }) =>
      axios.put(`${apiUrlV4}schedule/${schedule._id}/unlatch`, {
        game_id: schedule.game_id,
        email,
      });

    return useMutation(
      {
        mutationKey: ['mutation-schedule-unlatch'],
        mutationFn: unlatchSchedule,
        onError: (error) => {
          snackbarService.error("Can't unlatch schedule. Please, try later.");
          console.error("Can't unlatch schedule:", error);
        },
      },
    );
  };

  const GetScheduleById = ({ schedule_id }) => {
    return axios.get(`${apiUrlV3}schedule/${schedule_id}/`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  const useGetScheduleById = (scheduleId: string) => {
    const getScheduleById = () => axios
      .get(`${apiUrlV4}schedule/${scheduleId}`)
      .then((res) => res.data);

    return useQuery(
      {
        queryKey: ['get-schedule-by-id', scheduleId],
        queryFn: getScheduleById,
        onError: (error) => {
          snackbarService.error('Error getting schedule. Try again later.');
          console.error('Error getting schedule:', error);
        },
        refetchOnWindowFocus: false,
        enabled: !!scheduleId, // Fetch when has the params
      },
    );
  };

  const useGetTeamSchedules = (payload: GetTeamSchedulesPayload) => {
    const getTeamSchedules = () => {
      const dateParams = {
        date: dateFormat(payload.date, 'YYYY-MM-DD'),
        end_date: dateFormat(payload.end_date, 'YYYY-MM-DD'),
      };

      return axios.post(
        `${apiUrlV4}schedule/getTeamSchedules`,
        { ...payload, ...dateParams, offset: new Date().getTimezoneOffset() },
      ).then((res) => {
        const resData: GetTeamSchedulesRes = res.data;
        return resData;
      });
    };

    return useQuery({
      queryKey: ['get-team-schedules', payload],
      queryFn: getTeamSchedules,
      onError: (error) => {
        snackbarService.error("Can't get team schedules. Please, try later.");
        console.error('Error in get team schedules:', error);
      },
      refetchOnWindowFocus: false,
      enabled: !!payload,
    });
  };

  const MergeSchedules = () => {
    const mergeSchedules = (payload: IMergeSchedules) => axios
      .post(`${apiUrlV4}schedule/merge-schedules`, payload);

    return useMutation(
      'mutation-merge-schedules',
      mergeSchedules,
      {
        onSuccess: (res) => {
          if (!res.data.success) {
            snackbarService.error(res.data.message);
            return res.data;
          }
          snackbarService.success('Schedules are merged!');
          return res.data;
        },
        onError: (error) => {
          const apiError = error as AxiosError<{ message: string }>;
          snackbarService.error(apiError.response.data.message ?? "Can't merge schedules. Please, try later.");
          console.error('Error in merge:', error);
        },
      },
    );
  };

  const GetSchoolDataFromSchedule = () => {
    const getSchoolDataFromSchedule = (scheduleId: string) => axios
      .get(`${apiUrlV4}schedule/${scheduleId}/school-data`);

    return useMutation(
      'mutation-get-school-data-from-schedule',
      getSchoolDataFromSchedule,
      {
        onError: (error) => {
          snackbarService.error("Can't get school data from schedule. Please, try later.");
          console.error('Error in get school data from schedule:', error);
        },
      },
    );
  };

  const useGetSchedulesByDevice = (payload: GetSchedulesByDevicePayload) => {
    const dateParams = {
      date: dateFormat(payload?.date, 'YYYY-MM-DD'),
      end_date: dateFormat(payload?.end_date, 'YYYY-MM-DD'),
    };

    const getSchedulesByDevice = () => axios
      .post(`${apiUrlV4}schedule/get-by-device`, { ...payload, ...dateParams })
      .then((res) => {
        const resData: GetSchedulesByDeviceRes = res.data;
        return resData;
      });

    return useQuery({
      queryKey: ['get-schedules-by-device', payload],
      queryFn: getSchedulesByDevice,
      onError: (error) => {
        snackbarService.error('Error getting schedules by device. Try again later.');
        console.error('Error getting schedules by device:', error);
      },
      refetchOnWindowFocus: false,
      cacheTime: 0,
    });
  };

  const useGetTournaments = () => {
    const getTournaments = () => axios
      .get(`${apiUrlV4}tournaments`)
      .then((res) => {
        const resData: TournamentsRes = {
          ...res.data,
          data: res.data.data.map(({ tournament_id, name }) => ({ value: tournament_id, label: name })),
        };

        return resData;
      });

    return useQuery(
      {
        queryKey: ['query-tournaments'],
        queryFn: getTournaments,
        onError: (error) => {
          snackbarService.error("Can't get tournaments. Please, try later.");
          console.error("Can't get tournaments:", error);
        },
        refetchOnWindowFocus: false,
      },
    );
  };

  return {
    InactivateSchedule,
    useInactivateSchedule,
    useAddSchedule,
    useUpdateSchedule,
    useMakeLive,
    useUnlatchSchedule,
    usePartialUpdateSchedule,
    GetScheduleById,
    useGetScheduleById,
    useGetTeamSchedules,
    MergeSchedules,
    GetSchoolDataFromSchedule,
    useGetSchedulesByDevice,
    useGetTournaments,
  };
};

/*
* @info Mutation to save the season schedules to backend
* As params accept [Array of {schedules} ] to be saved;
* Each schedule will be validated and if valid saved in DB;
* Not valid schedules will not be saved, and returned with error message in response;
*
* @response array of [{schedule},{schedule}] with error message if any;
* */
export const SaveSchedulesBatch = (axios:AxiosInstance) => {
  return useMutation(
    'mutation-schedules-save-batch',
    (payload: any[]) => axios.put(
      `${apiUrlV4}schedule/add-many`,
      [...payload],
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error('Save schedules batch: ', err);
        snackbarService.error('Something went wrong with saving new schedule. Please try again later.');
      }),
  );
};

export const SetScheduleSeason = (axios:AxiosInstance) => {
  return useMutation(
    'mutation-schedules-set-season',
    (payload: ISeason) => axios.put(
      `${apiUrlV4}season`,
      { ...payload },
    ).then((res) => {
      return res.data;
    }).catch((err) => {
      console.error('Set season error: ', err);
      snackbarService.error('Something went wrong with setting season. Please try again later.');
    }),
  );
};
