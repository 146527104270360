import moment from 'moment-timezone';

export interface TimezoneConfig {
  offset: number;
  zone: string;
}

export const getTimezoneConfigByDate = (date: string): TimezoneConfig => {
  const dateWithTimezone = moment.tz(date, moment.tz.guess());
  const offset = dateWithTimezone.utcOffset() / 60;
  const zone = dateWithTimezone.zoneAbbr();

  return { offset, zone };
};
