interface Settings {
  widgetSgShowSchedules: boolean;
  primaryColor: string;
  secondaryColor: string;
  logoURL: string;
  logoUrlSmall: string;
  mascot: string;
}

export interface SchoolData {
  name: string;
  displayName: string;
  settings: Settings;
}

interface FacilityData {
  name: string;
  deviceSerial: string;
}

interface GameState {
  away: string;
  home: string;
  quarter: string;
  time: string;
  ts: string;
}

interface MappedGameState {
  away: string;
  home: string;
  gameTime: string;
}

export interface GameData {
  state: GameState;
  mappedState: MappedGameState;
}

interface DeviceInfo {
  interface: string;
}

export interface DeviceData {
  serial: string;
  firmwareVersion: string;
  lastOnlineTs: string;
  lastGameTickTs: string;
  online: boolean;
  deviceInfo: DeviceInfo;
  shadow: string;
  connectionType: ConnectionType;
  isLive: boolean;
}

export type DeviceUpdateData = Omit<DeviceData, 'isLive'>;

export interface Game {
  id: string;
  homeSchoolId: string;
  awaySchoolId: string | null;
  gameStartTs: string;
  isTbaTime: boolean;
  name: string;
  homeName: string;
  sport: string;
  grade: string;
  notes: string;
  gender: string;
  awayName: string;
  gameId: string;
  homeSchoolData: SchoolData | null;
  awaySchoolData: SchoolData | null;
  facilityData: FacilityData;
  gameData: GameData;
  deviceData: DeviceData;
  flagged: boolean;
  finalized: boolean;
  noAutoFinalize: boolean;
}

export enum ConnectionType {
  Ethernet = 'Ethernet',
  WiFi = 'WiFi',
  Cellular = 'Cellular',
}

export enum GamesAdminTabType {
  AllGames = 'allGames',
  Finalized = 'finalized',
  DevicesOnlineWOGame = 'devicesOnlineWOGame',
  DevicesOnlineWGame = 'devicesOnlineWGame',
  GamesWNotes = 'gamesWNotes',
}

export const GamesAdminTabTypeLabel: Record<GamesAdminTabType, string> = {
  [GamesAdminTabType.AllGames]: 'All Games',
  [GamesAdminTabType.Finalized]: 'Finalized',
  [GamesAdminTabType.DevicesOnlineWOGame]: 'Devices Online w/o Game',
  [GamesAdminTabType.DevicesOnlineWGame]: 'Devices Online w/Game',
  [GamesAdminTabType.GamesWNotes]: 'Games w/Notes',
};

export enum GameCategory {
  Flagged = 'flagged',
  Live = 'live',
  Upcoming = 'upcoming',
  Final = 'final',
  Missed = 'missed',
}

export const GameCategoryLabel: Record<GameCategory, string> = {
  [GameCategory.Flagged]: 'Flagged',
  [GameCategory.Live]: 'Live',
  [GameCategory.Upcoming]: 'Upcoming',
  [GameCategory.Final]: 'Final',
  [GameCategory.Missed]: 'Missed',
};

export type GamesAdminData = Record<GameCategory, Game[]>;
